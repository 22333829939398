.about-goal__title {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 1.8em;
        right: 0;
        width: calc(100% - 16em);
        height: 1px;
        background-color: var(--color__red);
    }
}

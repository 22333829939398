.project {
    .wpb_single_image { 
        .vc_single_image-wrapper,
        .vc_figure {
            display: block;
        }
    }
    .vc_single_image-img {
        width: 100%;
    }
}

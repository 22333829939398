.widget_products {
  padding-top: 0 !important;

  .woocommerce-placeholder,
  .attachment-woocommerce_thumbnail {
    display: none;
  }

  li {
    .product-title {
      br {
        display: none;
      }
    }

    .postid-734 &.prod-734 {
      .product-title {
        color: var(--color__red) !important;
      }
    }

    del,
    ins {
      display: none;
    }
  }
}

.woocommerce {
  .products {
    margin-bottom: 0 !important;

    .type-product {
      @media (--phone){
        float: none !important;
        width: 70% !important;
        max-width: 16em;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .woocommerce-LoopProduct-link {
        display: block;
        position: relative;
        @mixin itemDorn;
      }

      .attachment-woocommerce_thumbnail {
        margin-bottom: 0;
      }

      .img-wrap {
        position: relative;
        height: 10em;
        border: 2px solid var(--color__gray_dark);

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: color( var(--color__gray_dark) a(.7) );
        }

        &:after {
          content: 'read more';
          position: absolute;
          top: 50%;
          left: 50%;
          padding: .2em .4em;
          border: 1px solid white;
          border-radius: .3em;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
          font: 400 16px/1.2 'Open Sans', sans-serif;
          color: white;
        }

        &:before,
        &:after {
          opacity: 0;
          transition: opacity .2s linear;
        }

        &:hover {
          &:before,
          &:after {
            opacity: 1;
          }
        }

        img {
          display: block;
          width: auto;
          height: 100%;
          margin: 0 auto;
          object-fit: cover;

          .home-products & {
            width: 100%;
          }
        }
      }

      .button {
        display: none;
      }

      .woocommerce-loop-product__title {
        text-transform: uppercase;
        font: bold 14px/1.2 'open sans', serif;
        color: var(--color__text);
        @media (--phone){
          font-size: 1rem;
        }
      }

      .whatsapp {
        display: block;
        width: 100%;
        padding: .5em 0;
        margin-top: 6px;
        background-color: var(--color__whatsapp);
        border-bottom: 4px solid transparent;
        text-transform: uppercase;
        font: 400 14px/1.2 'Open Sans', serif;
        color: white;
        transition: border-color .4s linear;

        &:hover {
          border-bottom-color: color(var(--color__whatsapp) b(+15%));
        }

        svg {
          width: 2em;
          vertical-align: middle;
        }
      }
    }
  }

  /* WOOCOMMERCE COMMONS */
  &.woocommerce-page {
    .woocommerce-result-count,
    .woocommerce-ordering,
    .woocommerce-notices-wrapper,
    .woocommerce-breadcrumb,
    .woocommerce-products-header,
    .price,
    .onsale {
      display: none !important;
    }

    .content-wrapper {
      padding-top: 3em;
    }

    --widget__pl: 15px;
    .woo__head {
      position: relative;
      padding-bottom: 2em;
      padding-left: var(--widget__pl);
      @media (--phone){
        padding-left: 0;
        padding-bottom: 1em;
      }

      &:before {
        content: '';
        position: absolute;
        top: 1.4em;
        right: 0;
        width: calc(100% - 14em);
        height: 1px;
        background-color: var(--color__red);
      }
    }

    @mixin haveWidgetPage;

    /* SINGLE PRODUCT */
    &.single-product {
      .woocommerce-breadcrumb {
        display: block;
        font-size: 16px;
        color: var(--color__red);
      }

      .product {
        overflow: hidden;
        padding-bottom: 10px;
      }

      h2.title > br,
      .onsale {
        display: none;
      }

      .content-wrapper {
        padding-bottom: 3em;
        @media (--phone){
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .woocommerce-product-gallery {
        float: none !important;
        width: 100% !important;
        margin-top: 1em;
        @media (--desktop){
          float: right !important;
          width: 48% !important;
        }

        .flex-control-nav {
          > li {
            width: calc(100% / 4);
            @media (--ipad_mini){
              width: calc(100% / 3);
            }
            @media (--phone){
              width: calc(100% / 4);
            }
          }
        }
      }

      .tabs.wc-tabs {
        display: none;
      }

      .woocommerce-tabs {
        @media (--desktop){
          float: left;
          width: 48%;
          clear: none !important;
        }
      }

      .woocommerce-Tabs-panel--description {
        font: 14px/1.2 'open sans', serif;

        > h2 {
          display: none;
        }

        h4 {
          margin-top: 2em;
          font-weight: bold;
          font-size: 16px;

          &:nth-child(2),
          &:nth-child(3){
            margin-top: 1em;
          }
        }

        ul {
          padding-left: 1.4em;
        }

        ul,
        p {
          margin-top: 1em;
        }
      }

      #tab-reviews,
      .related.products {
        display: none !important;
      }

      .popmake-179 {
        vertical-align: top;
      }

      .whatsapp {
        display: inline-block;
        padding: .4em 1em;
        margin: 12px 0 0 10px;
        border-radius: 4px;
        background-color: var(--color__whatsapp);
        font-size: 16px;
        color: white;
        vertical-align: top;

        svg {
          display: inline-block;
          width: 2.4em;
          height: 2.4em;
          vertical-align: middle;
        }

        &:hover {
          box-shadow: 2px 2px 0 #666;
        }
      }
    }
  }

  .woocommerce-pagination {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  &.postid-734 {
    .woocommerce-product-gallery {
      display: none !important;
    }

    .woocommerce-tabs {
      float: none !important;
      width: 100% !important;
    }

    .vc_col-sm-3 {
      padding-left: 10px;
      padding-right: 10px;
    }

    .wpb_single_image {
      display: flex;
      flex-flow: column wrap;
      
      .wpb_heading {
        order: 2;
        height: 2.8em;
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 1em !important;
        font: 400 .9rem/1.2 'Open Sans', sans-serif !important;
      }

      .vc_figure {
        border: 1px solid #888;
      }
    }
  }
}

.about-feature__item {
    position: relative;
    width: calc(100% / 5);
    padding-top: calc(100% / 5);
    border-bottom: 1px solid var(--color__gray_dark);
    @media (--ipad){
        width: calc(100% / 3);
        padding-top: calc(100% / 3);

        &:nth-child(3n):before {
            display: none;
        }
    }
    @media (--ipad_mini){
        width: calc(100% / 3);
        padding-top: calc(100% / 3);

        &:nth-child(3n):before {
            display: none;
        }
    }
    @media (--phone){
        width: calc(100% / 2);
        padding-top: calc(100% / 2);

        &:nth-child(2n):before {
            display: none;
        }
    }

    @media (--desktop){
        &:nth-child(5n):before {
            display: none;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: .5em;
        right: 0;
        width: 1px;
        height: calc(100% - 1em);
        background-color: var(--color__gray_dark);
    }
}

@for $i from 1 to 5 {
    .about-feature__item:nth-child($(i)) {
        background: url(../images/about_feature_$(i)@1x.png) calc(100% - .8em) 1em no-repeat;
    }
}

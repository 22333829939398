.socials__item {
  overflow: hidden;
  width: 10em;
  padding: .3em .5em;
  margin-left: auto;
  text-transform: capitalize;
  font: 400 1.2rem/1.2 arial, sans-serif;
  color: white;
  cursor: pointer;
  transition: transform .4s linear;

  &:hover {
    transform: translateX(-7.5em);

    .socials__img {
      max-height: 10em;
      transition: max-height .4s linear .4s;
    }
  }
}

@each $i in whatsapp, wechat, facebook {
  .socials__item_$(i) {
    @if $i == whatsapp { 
      background-color: #25d366;
    } @else if $i == wechat {
      background-color: #7bb32e;
    } @else if $i == facebook {
      background-color: #3b5998;
    }
  }
}

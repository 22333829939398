.owl-carousel {
  margin-bottom: 0 !important;
  margin-top: 1em;

  .sa_hover_container {
    text-align: center;
  }
}

@each $i in prev, next {
  .sa_owl_theme .owl-nav .owl-$(i) {
    @if $i == prev {
      transform: matrix(.7, 0, 0, .7, -40, 0);
    } @else {
      transform: matrix(.7, 0, 0, .7, 40, 0);
    }
    @media (--phone){
      transform: matrix(.7, 0, 0, .7, 0, 0);
    }
  }
}

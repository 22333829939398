.pp_pic_holder {
  @media (--phone){
    max-width: 100% !important;

    .ppt {
      width: 100% !important;
    }

    .pp_content {
      width: 100% !important;
    }

    #fullResImage {
      object-fit: contain;
    }
  }
}

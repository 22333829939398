.about-equipment__adorn {
    @media (--ipad){
        width: 1px;
        height: 3em;
        margin: 1em auto;
        background-color: var(--color__red);
    }
    @media (--ipad_mini){
        width: 1px;
        height: 3em;
        margin: 1em auto;
        background-color: var(--color__red);
    }
    @media (--phone){
        width: 1px;
        height: 3em;
        margin: 1em auto;
        background-color: var(--color__red);
    }
}

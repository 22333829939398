.color {
    &_white {
        color: white;
    }

    &_red {
        color: var(--color__red);
    }

    &_gray {
        color: var(--color__gray);
    }

    &_gray_dark {
        color: var(--color__gray_dark);
    }
}

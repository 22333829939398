.btn {
    display: inline-block;
    padding: .4em 1em;
    border: 1px solid var(--color__text);
    border-radius: .2em;
    font: 14px/1.2 "Open Sans", serif;
    text-transform: uppercase;

    &:hover {
        background-color: var(--color__text);
        color: white;
    }
}

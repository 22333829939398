:root {
  --color__gray: #f1f2f2;
  --color__gray_dark: #bcbec0;
  --color__red: #ef1c23;
  --color__green: #25f247;
  --color__whatsapp: #12af0a;
  --color__text: #484749;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;
}

@define-mixin itemDorn {
  &:after,
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }

  &:before {
    background-color: var(--color__gray_dark);
    transform:  translateY(2px);
  }

  &:after {
    background-color: var(--color__red);
    transform-origin: left;
    transform:  scale(0, 1) translateY(2px);
    transition: transform .4s linear;
  }

  &:hover {
    &:after {
      transform:  scale(1, 1) translateY(2px);
    }
  }
}

@define-mixin haveWidgetPage {
  .content-wrapper > .row {
    display: flex;
    flex-flow: row wrap;
  }
  #primary {
    order: 2;
    width: 100%;
    @media (--desktop){
      width: calc(100% / 12 * 8.5);
    }
  }

  #secondary {
    width: 100%;
    @media (--desktop){
      width: calc(100% / 12 * 3.5);
    }
  }
}

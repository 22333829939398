.about-equipment__title {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 2em;
        left: 0;
        width: calc(100% - 17em);
        height: 1px;
        background-color: var(--color__red);
    }
}

.project__detail {
    position: relative;
    margin-top: 1em;
    padding-bottom: 1em;
    @mixin itemDorn;

    caption {
        font-size: 1rem;
        color: var(--color__text);
    }

    th,
    td {
        padding: 0;
        border: none;
        text-align: left;
        font: .9rem/1.2 'open sans', serif;
    }

    th {
        width: 12em;
        font-weight: normal;
        vertical-align: top;
    }
}

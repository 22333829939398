.about {
    &__btn {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 9em;
            width: 35em;
            height: 1px;
            background-color: var(--color__red);
            @media (--phone){
                width: 15em;
            }
        }
    }
}

.project-feature__title {
    position: absolute;
    bottom: .8em;
    left: .8em;
    width: calc( 100% - 1.6em);
    height: 3.8em;
    font-size: .8rem;
    @media (--phone){
        font-size: 1rem;
    }
}

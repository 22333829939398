.about-goal {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 3em;
        left: 0;
        width: 55%;
        height: 1px;
        background-color: var(--color__red);
        transform:  translateX(-30%);
        @media (--phone){
            bottom: 2em;
        }
    }
}

.home-product {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        right: 5em;
        top: 1.6em;
        width: calc(100% - 19.5em);
        height: 1px;
        background-color: var(--color__red);
        @media (--phone){
            display: none;
        }
    }
}

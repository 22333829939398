.wpcf7 {
    .wpcf7-form {
        --d: 1em;

        input {
            &[type="text"],
            &[type="email"],
            &[type="tel"] {
                margin-top: var(--d);
                background-color: transparent;
                font: 1rem/1.2 'open sans', serif;
                border-color: color( var(--color__text) a(.7));
                border-radius: 6px;
                color: var(--color__text);

                &::placeholder {
                    opacity: 1;
                    color: var(--color__text);
                }
            }

            &[name="your-name"],
            &[name="tel-150"],
            &[name="your-email"] {
                width: calc((100% - var(--d) * 2) / 3);
            }

            &[name="tel-150"],
            &[name="your-email"] {
                margin-left: var(--d);
            }

            &[name="your-message"] {
                width: 100%;
            }

            &[type="submit"] {
                display: block;
                padding: .5em 3em;
                margin: var(--d) auto 0;
                background-color: var(--color__red);
                border-color: var(--color__red);
                font: bolder 1rem/1.2 'open sans', serif;
                color: white;

                &:hover {
                    background-color: transparent;
                    color: var(--color__red);
                }
            }
        }

        .ajax-loader {
            display: none;
        }
    }
}

@define-mixin arrow {
  display: block;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    width: 4px;
    height: 4px;
    border-bottom: 1px solid var(--color__text);
    border-right: 1px solid var(--color__text);
  }
}

.widget_products,
.widget_product_categories {
  padding-right: 2em;
  @media (--phone){
    padding-top: 1em;
    padding-right: 0;
  }

  .widget-title {
    padding-bottom: 0;
    border-bottom: 1px solid var(--color__red);
    font: bold 16px/2 'open sans', serif;
    color: var(--color__red) !important;
    @media (--phone){
      position: relative;
      padding-left: 1em;
      background-color: var(--color__red);
      color: white !important;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1em;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid white;
        transform: matrix(1, 0, 0, 1, 0, -3.5);
        transition: transform .2s;
      }

      &_active:after {
        transform: matrix(0, 1, -1, 0, 0, -3.5);
      }
    }
  }

  .product_list_widget,
  .product-categories {
    @media (--phone){
      display: none;
    }

    .li,
    .cat-item {
      line-height: 1;

      &.current-cat > a {
        color: var(--color__red) !important;
      }
    }

    > li,
    > .cat-item {
      padding: .6em 0 !important;
      border-bottom: 1px solid var(--color__gray_dark);

      &:last-child {
        border-bottom: none !important;
      }

      &.cat-parent { 
        padding-right: 1em;

        > a {
          @mixin arrow;

          &:before {
            right: 0;
            transform: matrix(1, 1, -1, 1, 0, -5);
          }
        }
      }
    }

    .children {
      padding-top: 5px;
      padding-left: 1em;

      a {
        @mixin arrow;

        &:before {
          left: 0;
          transform: matrix(1, -1, 1, 1, -14, -4);
        }
      }
    }

    a {
      font: bold 14px/1.2 'open sans', serif;

      &:hover {
        color: var(--color__red) !important;
      }
    }
  }
}

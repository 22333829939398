.banner__adorn {
    position: relative;
    height: 1em;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &:before {
        background-color: black;
        clip-path: polygon(0 0, 100% 0, 100% 100%);
    }

    &:after{
        background-color: var(--color__red);
        clip-path: polygon(0 0, 100% 0, 100% 10%, 0 100%);
    }
}

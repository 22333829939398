.footer-widgets {
  padding: 3em 0 1em;
  font-size: .9rem;

  address {
    margin-bottom: 0;
  }

  .sidebar-column:first-child:after {
    content: '';
    display: table;
    clear: both;
  }

  #media_image-2 {
    display: flex;
    float: left;
    width: calc(100% - 16em);
    position: relative;
    flex-flow: column nowrap;

    &:after {
      content: '';
      position: absolute;
      bottom: 2em;
      right: 0;
      width: calc(100% - 200px);
      height: 1px;
      background-color: var(--color__red);
    }

    .widget-title {
      order: 2;
      padding-bottom: 0;
      font: bold .9rem/1.2 'open sans', serif;
    }
  }

  #media_image-3 {
    float: right;
    max-width: 15em;
    margin-bottom: 0;
    padding-top: 0;
    @media (--phone){
      max-width: 16em;
      padding-top: 3px;
    }
  }

  .menu {
    a {
      display: block;
      line-height: 1;
      font-size: .9rem;
    }

    @media (--ipad){
      &-item {
        display: inline-block;

        + .menu-item {
          margin-left: 1em;
        }
      }
    }
    @media (--ipad_mini){
      &-item {
        display: inline-block;

        + .menu-item {
          margin-left: 1em;
        }
      }
    }
    @media (--phone){
      &-item {
        display: inline-block;

        + .menu-item {
          margin-left: 1em;
        }
      }
    }
  }

  .sidebar-column:last-child {
    display: flex;
    flex-flow: row wrap;
    @media (--ipad){
      display: block;
    }
    @media (--ipad_mini){
      display: block;
    }
    @media (--phone){
      display: block;
    }

    .widget_nav_menu {
      flex: 1;
    }

    .FollowusonWidget {
      flex: 1;
      padding-top: 0;

      .widget-title {
        margin-top: 0;
        padding-bottom: 1em;
        font-size: 1rem;
        font-weight: 400;
        @media (--phone){
          padding-bottom: 0;
        }
      }
    }
  }

  @media (--ipad){
    .widget {
      margin-bottom: 2em;
    }
  }
  @media (--ipad_mini){
    .widget {
      margin-bottom: 2em;
    }
  }
  @media (--phone){
    .widget {
      margin-bottom: 2em;
    }
  }
}

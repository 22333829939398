.site-header {
  padding: 20px 0;
  @media (--phone){
    padding: 10px 0;
  }

  .col-md-8.col-sm-4.col-xs-2 {
    position: static;
  }
}

#mainnav {
  &-mobi {
    left: 0;
    margin-top: 0;
  }

  .menu > .menu-item {
    &.current_page_item,
    &.current_page_parent,
    &.current-menu-item {
      > a {
        display: block;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: -10%;
          left: -10%;
          width: 120%;
          height: 120%;
          border: 1px solid var(--color__red);
        }
      }
    }

    &:hover > .sub-menu {
      left: 0;
    }

    .sub-menu {
      left: 0;
      width: 154px;

      &:after {
        display: none;
      }

      a {
        width: 154px;
        padding: .2em .5em !important;
        text-transform: uppercase;
        font: 14px 'open sans', serif;
      }
    }
  }
}

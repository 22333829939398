.coverage {
    .wpb_single_image {
        display: flex;
        position: relative;
        padding: .4em 0;
        flex-flow: column nowrap;
        align-content: center;
        perspective: 10em;
        @media (--ipad){
            margin-top: 1em;
        }
        @media (--ipad_mini){
            margin-top: 1em;
        }
        @media (--phone){
            margin-top: 1em;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 50%;
            background-color: color(var(--color__green) l(+30%) b(+7%) a(.6));
            opacity: 0;
            transform-style: preserve-3d;
            transition: transform .5s linear,
                        opacity .1s linear .2s;
        }

        &:before {
            left: 0;
            transform-origin: left;
        }

        &:after {
            right: 0;
            transform-origin: right;
        }

        .wpb_heading {
            order: 2;
            margin-top: 1em;
            padding: .3em 0;
            font: 16px/1.2 'Open Sans', serif;
        }

        &:hover {
            &:before,
            &:after {
                opacity: 1;
                transition: transform .5s linear;
            }

            &:before {
                transform: rotateY(70deg);
            }

            &:after {
                transform: rotateY(-70deg);
            }
        }
    }
}
